@tailwind base;
@tailwind components;
@tailwind utilities;


/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #c5c5c5;
    border-radius: 5px;
  
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #131726;
    
    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #491131;
    
  }